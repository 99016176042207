// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpinnerLoader-module__LPqFs{display:flex;justify-content:center}.SpinnerLoader-module__Kn5Il{width:32px;height:32px;fill:#e0f9ff;animation:SpinnerLoader-module__wYZ_4 2s linear infinite}@keyframes SpinnerLoader-module__wYZ_4{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/components/SpinnerLoader/SpinnerLoader.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,sBAAA,CAGF,6BACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,wDAAA,CAIF,uCACE,GACE,sBAAA,CAGF,KACE,wBAAA,CAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n}\n\n.icon {\n  width: 32px;\n  height: 32px;\n  fill: #e0f9ff;\n  animation: spin 2s linear infinite;\n}\n\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SpinnerLoader-module__LPqFs",
	"icon": "SpinnerLoader-module__Kn5Il",
	"spin": "SpinnerLoader-module__wYZ_4"
};
export default ___CSS_LOADER_EXPORT___;
